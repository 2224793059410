const ProjectsInfo = [
    {
        title: "Distribution Center",
        description: "Projeto de 2021 de centro de distribuição e vendas. O sistema tem a capacidade de vender, estocar" +
            " e gerenciar um grande centro de distribuição, possui um app integrado e sistema complexo de rotas. Fantástico não?",
        link: "distribution-center",
        images: [
            {
                image: "cella_login.png",
                description: ""
            },
            {
                image: "cella_disttib.png",
                description: ""
            },
            {
                image: "cella_3.png",
                description: ""
            },
            {
                image: "cella_4.png",
                description: ""
            }
        ]
    },
    {
        title: "School Library",
        description: "Projeto de 2017 de gerenciamento integrado para biblioteca de qualquer porte, tem a capacidade de gerar selos de controle" +
            " seguindo as regras ISBN para código de barras, o sistema é responsivo e permite o acesso de usuários para requisitar, regular estado, ver catálogo" +
            " agendar retirada e realizar pagamentos (seja por atraso ou extensão de prazos).",
        link: "school-library",
        images: [
            {
                image: "library.png",
                description: ""
            },
            {
                image: "library1.png",
                description: ""
            },
            {
                image: "library2.png",
                description: ""
            },
            {
                image: "library3.png",
                description: ""
            },
        ]
    },
    {
        title: "Broker Management",
        description: "Projeto de 2019 que visava o desenvolvimento de um gestor multiplataforma de corretora de seguros. " +
            " O projeto foi grande e inteiramente feito em Spring Boot e MySQL, permitia a " +
            "extensão de múltiplos bancos de dados como Tenants (inquilinos), auto gerenciado e configurado pela própria plataforma.",
        link: "broker-management",
        images: [
            {
                image: "ass.png",
                description: ""
            },
            {
                image: "ass1.png",
                description: ""
            },
            {
                image: "ass2.png",
                description: ""
            },
            {
                image: "ass3.png",
                description: ""
            },
            {
                image: "ass4.png",
                description: ""
            },
        ]
    },
    {
        title: "Human Resources",
        description: "Projeto desenvolvido em 2018 para gestão de recursos humanos, todo o tratamento de pessoal da empresa passa " +
            " pelo sistema, seus cargos, e informações completas de usuários. O mais legal foi que tive que fazer de forma modular" +
            " que permitisse a adição fácil de novas funcionalidades sem a necessidade de interromper o servidor, o novo pacote entrava on-air " +
            " no ato da homologação.",
        link: "grh",
        images: [
            {
                image: "grh.png",
                description: ""
            },
            {
                image: "grh1.png",
                description: ""
            },
            {
                image: "grh2.png",
                description: ""
            },
            {
                image: "grh3.png",
                description: ""
            },
            {
                image: "grh4.png",
                description: ""
            },
        ]
    },
    {
        title: "Company Store",
        description: "Website criado em 2012, o site empresarial com acesso de clientes, fornecia as notas fiscais online " +
            "de forma rápida, na época, com o inicio da modernidade WEB, foi uma ideia fantástica, o site trazia diversas informações " +
            "sobre os serviços da empresa, capacidade de realizar orçamentos de forma dinâmicas e inovava com publicidade embarcada " +
            "para clientes da mesma cidade que queriam alavancar seu comercio de forma mais rápida através da empresa que servia de " +
            "ponto de encontro.",
        link: "company-store",
        images: [
            {
                image: "gts1.png",
                description: ""
            },
            {
                image: "gts2.png",
                description: ""
            },
            {
                image: "gts3.png",
                description: ""
            },
            {
                image: "gts4.png",
                description: ""
            },
        ]
    },
    {
        title: "Cultural Museum",
        description: "Projeto de 2022, website de apresentação de dados históricos e culturais de uma cidade, qualquer informações " +
            " reconhecida como dado cultural ou marco histórico é migrado para que o cidadãos possam ter acesso irrestrito sobre a existência " +
            "da cidade. Essa projeto ainda está em andamento inclusive!! Legal não?",
        link: "cultural-museum",
        images: [
            {
                image: "museucatu_1.png",
                description: ""
            },
            {
                image: "museucatu_2.png",
                description: ""
            },
            {
                image: "museucatu_3.png",
                description: ""
            }
        ]
    },
    {
        title: "Company Stock",
        description: "Projeto de 2014, ERP direcionado como um sistema de estoque, mas possui todo um controle voltado para" +
            " gestão de fazenda, gado, materiais agrícolas e etc, o dono do sistema fez o pedido para ter a gerência completa de suas fazendas, informatizando" +
            " a maioria dos processos, ate suas requisições, documentos médicos de animais, colaborados entram no sistema.",
        link: "company-stock",
        images: [
            {
                image: "mxStock.png",
                description: ""
            },
            {
                image: "mxStock_adminpanel.png",
                description: ""
            }
        ]
    }
];

export default ProjectsInfo
