import styled from 'styled-components'


const ProjectItemContainer = styled.div`
  width: 25vw;
  max-height: ${props => props.fix ? "unset" : "24vh"} ;
  height: fit-content;
  display: ${props => props.active ? "flex" : "none"};
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 2px 4px 9px rgba(0,0,0,.33);
  margin: 12px;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: all 200ms linear;
  border-radius: 8px;
  overflow: hidden;
  animation-name: flipInY;
  animation-duration: 600ms;
  opacity: 1;
   
  & .title {
    text-align: center;
    font-family: ZenKurenaido-Regular,sans-serif;
    font-size: 18pt;
    color: #fff;
    background: rgba(0,0,0,.13);
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  &:hover {
    width: 28vw;
    z-index: 10;
    transition: all 200ms linear;
    transform: ${props => props.fix ? "scale(1.1)" : "scale(1.2)"};
    height: fit-content;
  }
  
  & > h1 {
    text-wrap: none;
    text-align: center;
    position: absolute;
    bottom: 0;
    font-family: ZenKurenaido-Regular, sans-serif;
    font-size: 4em;
    color: #fff;
  }
  
  & img {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: all 300ms linear;
    
  }
  .loading {
    color:#fff;
    font-family: ZenKurenaido-Regular,sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

export default ProjectItemContainer;
