import React, {useRef, useState} from "react";
import GlobalLayout from "../layout/global";
import styled from 'styled-components'
import withReveal from "react-reveal/withReveal";
import Flip from "react-reveal/Flip";
import ProjectsInfo from "../data/projects.info";
import ProjectView from "../components/ProjectItem";
import Button from "../components/Button";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import Tooltip from "../components/Tooltip";
import {Router, Link} from "@reach/router"
import ProjectDetails from "../components/pages/projects/Details";
import {GiReturnArrow} from "react-icons/all";
import {BackButton} from "../components/pages/projects/Details/style";

const MainProject = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ProjectShowCase = styled.div`
  width: 95%;
  height:100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  transition: all 200ms linear;
  
   & > p {
    font-family: ZenKurenaido-Regular,sans-serif;
    margin-top: 5px;
    color: #fff;
    width: 80%;
    font-size: 14pt;
    transition: all 200ms linear;
   }
   & a, button {
   transition: all 200ms linear;
    width: 20vw;
   }
   & > a {
    margin-top: 5px;
   }
   
  & .arrow {
     color: #5d5d5d;
     box-shadow: 1px 2px 5px rgb(0 0 0 / 44%);
     border-radius: 7px;
     position:absolute;
     font-size: 42px;
     display: block;
     top: 50%;
     transform: translateY(-50%);
     transition: all 200ms linear;
     z-index: 10;
     cursor: pointer;
  }
  
  & .left {
    left: 1.5%;
  }
  & .right {
    right: 1.5%;
  }
  & h1 {
    font-family: ZenKurenaido-Regular,sans-serif;
    color: #fff;
  }
  
`

const AccordionItems = withReveal(styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  
 
`, <Flip bottom delay={400}/>)

const AccordionContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`

const InfoProjects = withReveal(styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  text-align: center;
  font-family: ZenKurenaido-Regular,sans-serif;
  justify-content: center;
  align-items: center;
  
  & h1 {
    font-size: 4em;
    margin-bottom: 6px;
  }
  & h3 {
    width: 60%;
    font-weight: normal;
  }
   
 
`, <Flip top delay={200}/>);

function scrollItem(startIn, setStartIn, limitPerPage, toBack) {
    if (!toBack) {
        if (startIn + 1 <= ProjectsInfo.length - limitPerPage) {
            let sum = 1 + startIn
            setStartIn(sum)
        }
    } else {
        if (startIn > 0) {
            setStartIn(startIn - 1)
        }
    }

}

const ProjectsMain = ({location}) => {
    const [currentProject, setCurrentProject] = useState(-1);
    const accRef = useRef(null)
    const showcaseRef = useRef(null)
    const limitPerPage = 3;
    const [startIn, setStartIn] = useState(0);
    return (<GlobalLayout pageTitle={"Kevin Santana Projetos"} location={location}>
        <MainProject>
            <ProjectShowCase ref={showcaseRef}>
                <InfoProjects style={currentProject > -1 ? {display: "none"} : {}}>
                    <h1>Projetos</h1>
                    <h3>Segue <strong>alguns</strong> dos projetos que já trabalhei. Sinta-se em casa.
                        <Tooltip clean
                                 tooltip={" Naturalmente," +
                                 " visto que muitos dos projetos em que eu já trabalhei são sigilosos devido a cláusulas contratuais, " +
                                 "infelizmente não posso adicioná-los a galeria. XD"}
                        />
                    </h3>
                </InfoProjects>
                <AccordionItems onClick={(e) => {
                    if (currentProject > -1)
                        setCurrentProject(-1)
                }}>
                    <AccordionContent ref={accRef}>
                        {ProjectsInfo.map(((item, i) => {
                            return (i < limitPerPage + startIn && i >= startIn
                                && <ProjectView fix={currentProject > -1 && currentProject === i}
                                                active={currentProject < 0 || currentProject === i}
                                                title={item.title}
                                                key={i}
                                                image={item.images[0] && item.images[0].image} onClick={(e) => {
                                    setCurrentProject(i)
                                }}/>
                            )
                        }))}
                    </AccordionContent>
                </AccordionItems>
                {currentProject < 0 &&
                <IoIosArrowBack onClick={(e) => scrollItem(startIn, setStartIn, limitPerPage, true)}
                                className={"arrow left"}/>}
                {currentProject < 0 && <IoIosArrowForward onClick={(e) => scrollItem(startIn, setStartIn, limitPerPage)}
                                                          className={"arrow right"}/>}
                <h1>{currentProject > -1 ? ProjectsInfo[currentProject].title : ""}</h1>
                <p>{currentProject > -1 ? ProjectsInfo[currentProject].description : ""}</p>
                {currentProject > -1 &&
                <Link to={`${ProjectsInfo[currentProject].link}/`}><Button>Ver
                    Projeto</Button></Link>}
            </ProjectShowCase>
        </MainProject>
        {currentProject > -1 && <BackButton onClick={(e) => {
            if (currentProject > -1)
                setCurrentProject(-1)
        }}>
            <div >
                <GiReturnArrow/>
            </div>
        </BackButton>}
    </GlobalLayout>)
}

const Projects = ({location}) => {
    return (
        <Router className={"inherit"}>
            <ProjectsMain path="projects" location={location}/>
            <ProjectDetails path="projects/:project" location={location}/>
        </Router>
    )


}
export default Projects
