import GlobalLayout from "../../../../layout/global";
import React from "react";
import ProjectsInfo from "../../../../data/projects.info";
import MainContainer, {
    BackButton,
    ImageSlide,
    Notify,
    Paragraph,
    SliderWrapper,
    SubTitle,
    TitleContainer
} from "./style";
import {Zoom} from 'react-slideshow-image';
import {GiReturnArrow} from "react-icons/all";
import {Link} from "@reach/router";

const getProjectIndexByLink = (linkName) => {
    return ProjectsInfo.findIndex((p) => p.link === linkName);
}

const ProjectDetails = (props) => {
    const id = getProjectIndexByLink(props.project)
    const ProjectData = ProjectsInfo[id];
    const properties = {
        scale: 1.4,
        indicators: i => (<div className={"indicators"}/>)
    }
    return (<GlobalLayout pageTitle={"Kevin Santana - Projeto " + ProjectData.title} location={props.location}>
        <BackButton>
            <Link to={'/projects'}>
                <GiReturnArrow/>
            </Link>
        </BackButton>
        <MainContainer>
            <TitleContainer>{ProjectData.title}</TitleContainer>
            <Notify>Então. Nem tudo é flores, então vou deixar avisado que o nível de detalhamento de cada projeto é bem
                mínimo.
                Apesar de que eu tenha feito os projetos e existirem projetos com cláusulas mais amplas, ainda não é
                possível
                e não tem nem lógica de eu expor aqui regras de negócios alheios não é mesmo meu camarada, então segue
                ai um SlideShow
                legalzinho! Um abraço :)</Notify>

            <SubTitle>Sinopse Básica do Sistema</SubTitle>
            <Paragraph>{ProjectData.description}</Paragraph>

        </MainContainer>
        <MainContainer>
            <SliderWrapper>
                <div className={"content"}>
                    <Zoom {...properties}>
                        {ProjectData.images.map((data, index) => {
                            return <ImageSlide key={index} style={{backgroundImage: `url(/projects/${data.image})`}}/>
                        })}
                    </Zoom>
                </div>
            </SliderWrapper>
        </MainContainer>
    </GlobalLayout>)
}

export default ProjectDetails
