import ProjectItemContainer from "./style";
import React from "react";

const ProjectView = (props) => {
    let customStyle = props.fix ? {width: "45vw"} : {};
    return (<ProjectItemContainer {...props} style={customStyle}>
        <img src={`/projects/${props.image}`} alt={props.title}/>
    </ProjectItemContainer>)
}

export default ProjectView
