import styled from 'styled-components'
import withReveal from 'react-reveal/withReveal'
import Flip from 'react-reveal/Flip'
import React from "react";

const MainContainer = withReveal(styled.section`
  font-family: ZenKurenaido-Regular,sans-serif;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
`, <Flip top/>)

const TitleContainer = withReveal(styled.h1`
  font-size: 3em;
  margin: 20px;
  text-align: center;
`, <Flip bottom/>)

const ImageSlide = styled.div`
  width: 60vw;
  height: 30vw;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: all 200ms linear;
`
const Notify = withReveal(styled.i`
  font-size: 10pt;
`, <Flip left/>)

const SubTitle = withReveal(styled.h2`
  margin: 15px 0 ;
  line-height: 2;
`, <Flip right/>)

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  & > .content {
    display: block;
    width: 60vw;
    height: 30vw;
  }
`
const Paragraph = styled.p`
  line-height: 1.2;
  font-size: 14pt;
  margin: 10px 0;
`

const BackButton = styled.div`
  padding: 10px;
  position: absolute;
  bottom: 10vh;
  right: 8vw;
  background: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 100%;
  z-index: 100;
  cursor:pointer;
  
  & a {
    width: inherit;
    height: inherit;
  }
  & svg {
    width: 25px;
    height: 25px;
    fill: #ffffff;
    color: #fff;
    transition: all 200ms linear;
  }
  
  &:hover svg {
    fill: gold;
    transition: all 200ms linear;
  } 
`

export {
    TitleContainer,
    ImageSlide,
    Notify,
    SubTitle,
    SliderWrapper,
    Paragraph,
    BackButton
}
export default MainContainer
